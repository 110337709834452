import React from 'react';
import { Navbar, Container, Row, Col, Alert } from 'react-bootstrap';
import { Reset } from '../auth/reset';
//import ReCAPTCHA from "react-google-recaptcha";
//import Constants from "../constants";

export const PasswordReset = (props) => {
  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col lg={6} md={6} sm={12} className="mt-4">
            <Reset {...props}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};
