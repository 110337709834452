import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ListGroup, Container, ButtonGroup, Dropdown, Col, Row, Form, Button, Alert, Modal, Accordion, Card } from 'react-bootstrap';
import auth from '../auth/auth';
import Constants from "../constants";
import ReCAPTCHA from "react-google-recaptcha";

import QRCode from "react-qr-code";

import {Helmet} from "react-helmet";

import decodeJwt from 'jwt-decode';
import { getIPRange } from 'get-ip-range';

const API_ENDPOINT = Constants.API_ENDPOINT;

export const CustomProxy = ({props, reloadSearch }) => {
  // History hook
  const history = useHistory();

  var hostListRaw = [];

/*
  React.useEffect(() => {
    childFunc.current = searchReload
  }, []);
*/

//host_nameHostData

  const [customProxyListView, setCustomProxyListView] = useState([]);
  const [showProxyData, setShowProxyData] = useState(false);
  const [proxyDataView, setProxyDataView] = useState([]);
  const [proxy_nameProxyData, setProxy_nameProxyData] = useState('');


  const [host_nameHostData, setHost_nameHostData] = useState('');
  const [network, setNetwork] = useState('');
  const [hostList, setHostList] = useState([]);
  const [hostListView, setHostListView] = useState([]);
  const [unavailableHostListView, setUnavailableHostListView] = useState([]);
  const [error, setError] = useState('');
  const [outMessage, setOutMessage] = useState('');
  const [stdoutMessage, setStdoutMessage] = useState('');
  const [stderrMessage, setStderrMessage] = useState('');
  const [show, setShow] = useState(false);
  const [showHostData, setShowHostData] = useState(false);
  const [actionListView, setActionListView] = useState([]);
  const [proxyListView, setProxyListView] = useState([]);
  const [tariffsListView, setTariffsListView] = useState([]);
  const [hostDataView, setHostDataView] = useState([]);
  const [tariffsDataView, setTariffsDataView] = useState([]);
  const [showTariffsData, setShowTariffsData] = useState(false);

  const handleCloseProxyData = (host_id,ip,i) => setShowProxyData(false);


  const handleShowProxyData = async (data_in, i) => {
    var default_proxy_addr = "";
    console.log("handleShowProxyData");
    var data = [];
    data[0] = data_in;
    await setProxyDataView(data_in);
    await setProxyDataView(data_in);

    console.log("data_in");
    console.log(data_in);
    console.log("data");
    console.log(data);

    console.log("proxyDataView");
    console.log(proxyDataView);

    
    //const nextHostDataView = hostDataView.map((c, i) => {
    const nextProxyDataView = data.map((c, i) => {
        c.status = c.status;
        c.bind_link_url = API_ENDPOINT + "/back/qr-host/?host_id=" + encodeURIComponent(c.host_id);
        //c.bind_link_url = API_ENDPOINT + "/qr-host/host_id=" + encodeURIComponent(c.host_id);
        //c.bind_link_url = API_ENDPOINT + "/proxy/" + c.proxy_addr+ "/" + c.proxy_ext_addr + "/" + c.proxy_ext_port + "/agent/bind";


        if (c.ports) {
            console.log("in port cycle");
            console.log(c);
            c.ports.map((new_c,ii) => {
                 //new_c = data_in
                 if (new_c.type_port == "link"){
                     new_c.link_url = "https://" + new_c.proxy_addr + "/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/index.html";
                     new_c.link_view = "https://" + new_c.proxy_addr + "/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/index.html";
                     //new_c.link_url = "https://dev.cloudlocalnet.com/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/";
                     //new_c.link_view = "https://dev.cloudlocalnet.com/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/";
                      }
                 if (new_c.type_port == "port"){
                     new_c.link_url = "http://" + new_c.proxy_ext_addr + ":" + new_c.proxy_ext_port;
                     new_c.link_view = new_c.proxy_ext_addr + ":" + new_c.proxy_ext_port;
                      }


                 new_c.view = new_c.name + " " + new_c.value + " " + new_c.type_port + " ( " + new_c.proxy_addr + " ) " ;
                 return new_c;
                 console.log(new_c);
            });
          }
        return c;      
    });
    console.log("nextProxyDataView");
    console.log(nextProxyDataView);

    //setHostDataView(nextHostDataView);
    console.log(nextProxyDataView);
    console.log("handle show proxy data view");

    setProxy_nameProxyData(data_in.host);

    setShowProxyData(true);    

}

  const handleShow = async (data_in,i) => {
    console.log("handle show");
    console.log(data_in);
    const token = localStorage.getItem('token');
    const request_proxy = new Request(`${API_ENDPOINT}/listproxy`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    console.log(request_proxy);
    const response_proxy = await fetch(request_proxy);
    console.log(response_proxy);
    const data_proxy = await response_proxy.json();
    var default_proxy_addr = "";
    const nextProxyListView = data_proxy.map((p, i) => {
      if (i === 0) {
        default_proxy_addr = p.host;
        return p;      
        } else {
        //p.proxy_addr = data_in.proxy_addr;
        //p.proxy_ext_addr = data_in.proxy_ext_addr;
        //p.proxy_ext_port = data_in.proxy_ext_port;
        //default_proxy_addr = "";
        return p;      
       }
    });
    console.log("handle show proxy list view");
    console.log(nextProxyListView);
    setProxyListView(nextProxyListView);
    //const default_proxy_addr = data_proxy.[0].host;
    const request = new Request(`${API_ENDPOINT}/actionslist`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    const nextActionListView = data.map((c, i) => {
        c.proxy_addr = data_in.proxy_addr;
        c.proxy_ext_addr = data_in.proxy_ext_addr;
        c.proxy_ext_port = data_in.proxy_ext_port;
        if (c.ports) {
            c.ports.map((new_c,ii) => {
                 new_c.proxy_addr = default_proxy_addr;
                 return new_c;
            });
          }

        return c;      
    });
    console.log("handle show actions list view");
    console.log(actionListView);
    console.log(nextActionListView);
    setActionListView(nextActionListView);

    setShow(true);    
   };

  // Function to Bind Host
  const addProxyBind = async (data_in, index) => {
    const token = localStorage.getItem('token');
    console.log(data_in);
    const request = new Request(`${API_ENDPOINT}/proxy/${data_in.proxy_addr}/${data_in.proxy_ext_addr}/${data_in.proxy_ext_port}/agent/bind`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();

    setOutMessage("Bind:  result:" + data.message);
    console.log(data.message);
    if (data.message=="OK") {
    const nextHostListView = hostListView.map((c, i) => {
      if (i === index) {
        c.btnColor="warning";
        return c;
      } else {
        return c;
      }
    });
    setHostListView(nextHostListView);
    }
  };

  // Function to Bind Host
  const deleteProxyFromBase = async (data_in) => {
    console.log("Delete custom proxy");
    console.log(data_in);
    const query_data={
            "host":data_in.host,
            "ip":"",
            "port_min":"",
            "port_max":"",
            "price_multiplier":"1",
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/proxy-custom-del`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json", 
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    console.log(response);
    const data = await response.json();
    console.log(data);
    searchReload();


  };

  // Function to Bind Host
  const addProxyUnBind = async (data_in, index) => {
    const token = localStorage.getItem('token');
    const request = new Request(`${API_ENDPOINT}/proxy/${data_in.proxy_addr}/${data_in.proxy_ext_addr}/${data_in.proxy_ext_port}/agent/unbind`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    setOutMessage("UnBind: result:" + data.message);
    //console.log(data_in.host_id);
    console.log(data.message);

    if (data.message=="OK") {
    const nextHostListView = hostListView.map((c, i) => {
      if (i === index) {
        c.btnColor="success";
        return c;
      } else {
        return c;
      }
    });
    setHostListView(nextHostListView);
    }
  };

  // Function for del member from proxy
  const changeMemberFromProxy = async (group_id, action, proxy_name) => {
    //console.log(group_id)
    console.log("change member from proxy");
    console.log(group_id);
    console.log(action);
  
    const query_data={
            "action":action,
            "group_name":proxy_name,
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-member-status-custom-proxy`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();

    //await searchReload();

    var data_new = {};
    const request_get = new Request(`${API_ENDPOINT}/back/get-custom-proxy`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response_get = await fetch(request_get);
    const data_get = await response_get.json();

    const nextCustomProxyListView = data_get.map((c, i) => {
        var index_arr = 0;
        for(const element of c._members) {
             if (element.group_role == "admin")
                {
                  c._members[index_arr].btnAdminColor = "primary";
                } else {
                  c._members[index_arr].btnAdminColor = "secondary";
                }
             index_arr += 1;
         };

        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        if (c.status=="active") {
        // if (c.status=="open_session") {
           c.spltColor="success";
            }
      if (c.host == proxy_name) {
        data_new = c;
        return c;
      } else {
        return c;
      }
    });
        await setProxyDataView(data_new);
        await setCustomProxyListView(nextCustomProxyListView);

  };

  // Function to call submit
  const callSubmit = async (e) => {
    // Prevents page reload on wrongs creds
    if (e){
        e.preventDefault();
        console.log(e);
      };
    setError('');
    const token = localStorage.getItem('token');
    try {
     //search
    const request = new Request(`${API_ENDPOINT}/back/get-custom-proxy`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log("Custom Proxy");
    console.log(data);
    const customProxyListView = data.map((c, i) => {

        var index_arr = 0;
        for(const element of c._members) {
             if (element.group_role == "admin")
                {
                  c._members[index_arr].btnAdminColor = "primary";
                } else {
                  c._members[index_arr].btnAdminColor = "secondary";
                }
             index_arr += 1;
         };


        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        // if (c.status=="open_session") {
        if (c.status=="active") {
           c.spltColor="success";
            }
        return c;
         });
    console.log("callSubmit");
    console.log(data);
    await setCustomProxyListView(customProxyListView);
    }
     catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'REGISTER_USER_ALREADY_EXISTS') {
          setError('Email ID is already registered. Please use your credentials to login.');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };

  // Function to call submit
  const searchReload = async () => {
    // Prevents page reload on wrongs creds
    const token = localStorage.getItem('token');
    try {
     //search
    const request = new Request(`${API_ENDPOINT}/back/get-custom-proxy`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log(data)

    const tmpcustomProxyListView = data.map((c, i) => {
        var index_arr = 0;
        for(const element of c._members) {
             if (element.group_role == "admin")
                {
                  c._members[index_arr].btnAdminColor = "primary";
                } else {
                  c._members[index_arr].btnAdminColor = "secondary";
                }
             index_arr += 1;
         };
        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        if (c.status=="active") {
        // if (c.status=="open_session") {
           c.spltColor="success";
            }
        return c;
         });
    await setCustomProxyListView(tmpcustomProxyListView);
    }
     catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'REGISTER_USER_ALREADY_EXISTS') {
          setError('Email ID is already registered. Please use your credentials to login.');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };



  // Fetch user information on page load
  useEffect(() => {
    const fetchData = async (e) => {
    callSubmit(e);
    };
    fetchData();
    if (reloadSearch) {
      searchReload();
    }

  // eslint-disable-next-line
  }, [reloadSearch]);


  return (
    <>
      <h2>User Custom Proxy</h2>
      <Container className="mt-4">
                   <Row>
                       {customProxyListView && customProxyListView.map((data,i) =>
                      <Col className="mt-4" key={i}> 
                 <Row>
	        <Dropdown as={ButtonGroup} >
	          <Button variant={data.btnColor} id="button-for-proxy" onClick={(e) => handleShowProxyData(data,i)}> {data.host}  </Button>
	          <Dropdown.Toggle split variant={data.spltColor} id="dropdown-split-basic" />	    
	          <Dropdown.Menu>
	            <Dropdown.Item href="#/action-proxy-bind" onClick={(e) => changeMemberFromProxy("current", "bind", data.host)}>Bind to Group</Dropdown.Item>
	            <Dropdown.Item href="#/action-proxy-unbind" onClick={(e) => changeMemberFromProxy("current", "unbind", data.host)}>UnBind from Group</Dropdown.Item>
                    <Dropdown.Divider />
	            <Dropdown.Item href="#/action-proxy-delete" onClick={(e) => deleteProxyFromBase(data,i)}>Delete Proxy</Dropdown.Item>
	          </Dropdown.Menu>
	        </Dropdown> </Row>
                    </Col>
                        )}
                  </Row>






      <Modal show={showProxyData} onHide={handleCloseProxyData} >
        <Modal.Header closeButton>
          <Modal.Title>Proxy_Addr {proxy_nameProxyData}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

           <Card.Body>
            Proxy Members
               <ListGroup>
                       {proxyDataView._members && proxyDataView._members.map((proxy_member,iii) =>
                 <ListGroup.Item key={iii}>
                          {proxy_member.group_name}  {'   '}
                          <ButtonGroup>
                            <Button variant={proxy_member.btnDelColor}  onClick={(e) => changeMemberFromProxy(proxy_member.group_id, "del", proxy_nameProxyData)}> Del </Button>
                            <Button variant={proxy_member.btnBlockColor} onClick={(e) => changeMemberFromProxy(proxy_member.group_id, "block", proxy_nameProxyData)}> Block </Button>
                            <Button variant={proxy_member.btnAdminColor} onClick={(e) => changeMemberFromProxy(proxy_member.group_id, "admin", proxy_nameProxyData)}> Admin </Button>
                          </ButtonGroup>
                 </ListGroup.Item>
                     )}
              </ListGroup>
           </Card.Body>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      </Container>  
          

    </>
  );
};
