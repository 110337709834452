import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Button, Alert } from 'react-bootstrap';
import auth from './auth';
import Constants from "../constants";
import ReCAPTCHA from "react-google-recaptcha";



export const Reset = () => {
  // History hook
  const history = useHistory();

  // User information hook
  const [password, setPassword] = useState('');
  //const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const reCaptchaRef = React.createRef();

  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');

  //const token = '';

  // Function to call submit
  const callSubmit = async (e) => {
    // Prevents page reload on wrongs creds
    e.preventDefault();
    setError('');
    try {
      const reCaptchaToken = await reCaptchaRef.current.executeAsync();
      await reCaptchaRef.current.reset();
      const data = await auth.reset(token,password,reCaptchaToken);
      // Executes only when there are no 400 and 500 errors, else they are thrown as errors
      // Callbacks can be added here
      if (data) {
        history.push('/');
      }
    } 
    catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'LOGIN_BAD_CREDENTIALS') {
          setError('Incorrect credentials');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };

  return (
    <>
      <h2>Reset password</h2>
      <Form onSubmit={callSubmit}>
        <Form.Group controlId="formResetPssword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Enter new password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
        </Form.Group>
        <Alert variant='danger' style={ error!=='' ? {display:"block"} : {display:"none"}}>
          {error}
        </Alert>
            <ReCAPTCHA
              sitekey={Constants.CAPTCHA_SITE_KEY}
              size="invisible"
              ref={reCaptchaRef}
            />
        <Button variant="primary" type="submit" block>
          Set
        </Button>
      </Form>
    </>
  );
};
