import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Collapse, ListGroup, Navbar, Container, Row, Col, Alert, Button, ButtonGroup, Dropdown, Modal, Card, Accordion, Form } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import auth from '../auth/auth';
import Constants from "../constants";

import { AgentSearch } from '../utils/agentsearch';
import { CustomProxy } from '../utils/customproxy';

const API_ENDPOINT = Constants.API_ENDPOINT;

export const Home = (props) => {
  // History hook
  const history = useHistory();



  // User information hook
  const [user, setUser] = useState({
    "id": "",
    "email": "",
    "is_active": true,
    "is_superuser": false,
    "is_verified": false,
    "firstName": "",
    "lastName": "",
    "userPlan": [],
    "userBalance": "",
    "balanceExpire":"",
    "balanceExpireRound":""
  });
  const [group, setGroup] = useState({
    "btnGroupColor": "outline-light",
    "spltGroupColor": "outline-light",
    "members": []
  });

  //Custom action init data
  const [customAction, setCustomAction] = useState({
   "name" : "",
    "comment" : "",
    "dependencies" : ["test"],
    "conditions" : {
        "CPU" : "None",
        "MEM" : "None",
        "DISK" : "None"
    },
    "tags" : ["test"],
    "source" : "https://",
    "auth_type" : "None",
    "source_credentials" : "",
    "branch" : "",
    "source_path" : "",
    "source_run_file" : "run.sh",
    "action_timeout" : "30",
    "source_timeout" : "30",
    "environment_variables" : [],
    "ports" : []  ,
    "action_type": "private"
  });

  const [environment_variablesForAdd, setEnvironment_variablesForAdd] = useState({
    "name":"",
    "comment":"",
    "value":""
  });

  const [portsForAdd, setPortsForAdd] = useState({
    "name":"",
    "value":"",
    "port_type":"link",
    "vm_id":"",
    "comment":""
  });

  const [tariffsListView, setTariffsListView] = useState([]);
  const [tariffsDataView, setTariffsDataView] = useState([]);
  const [plansDataView, setPlansDataView] = useState([]);
  const [showTariffsData, setShowTariffsData] = useState(false);
  const [showPlansData, setShowPlansData] = useState(false);
  const [showGroupData, setShowGroupData] = useState(false);
  const [showCreateProxy, setShowCreateProxy] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);
  const [showDeletedGroups, setShowDeletedGroups] = useState(false);
  //const [groupView, setGroupView] = useState([]);
  const [createGroupName, setCreateGroupName] = useState('');
  const [createGroupComment, setCreateGroupComment] = useState('');
  const [renameGroupName, setRenameGroupName] = useState('');
  const [renameGroupComment, setRenameGroupComment] = useState('');
  const [addGroupId, setAddGroupId] = useState('');
  const [openIdForAdd, setOpenIdForAdd] = useState(false);
  const [memberForAdd, setMemberForAdd] = useState('');
  const [searchComponent, setSearchComponent] = useState('');
  const [customProxy, setCustomProxy] = useState('');

  const [createCustomProxyHost, setCreateCustomProxyHost] = useState('');
  const [createCustomProxyIp, setCreateCustomProxyIp] = useState('');
  const [createCustomProxyPortMin, setCreateCustomProxyPortMin] = useState('');
  const [createCustomProxyPortMax, setCreateCustomProxyPortMax] = useState('');


  const [dependenceForAdd, setDependenceForAdd] = useState('');
  const [tagForAdd, setTagForAdd] = useState('');




  const [createCustomActionName, setCreateCustomActionName] = useState('');

  const [outMessage, setOutMessage] = useState('');
  const [publicKey, setPublicKey] = useState('');

  const [stdOutMessage, setStdOutMessage] = useState('');
  const [stdErrMessage, setStdErrMessage] = useState('');

   const [reloadSearch, setReloadSearch] = useState(0);
   
  const reloadGroupData = async () => {
        const token = localStorage.getItem('token');
      // fetch group data 
        const request_group_data = new Request(`${API_ENDPOINT}/back/get-group`, {
        method: 'GET',
        headers: {'Authorization': `Bearer ${token}`}
        });
        console.log(request_group_data);
        const response_group_data = await fetch(request_group_data);
        console.log(response_group_data);
        const data_group_data = await response_group_data.json();
        data_group_data.btnGroupColor = "outline-light";
        data_group_data.spltGroupColor = "outline-light";
        data_group_data.balanceExpireRound = data_group_data.balanceExpire.slice(0, -7);
        console.log(data_group_data);

        var index_arr = 0;
        for(const element of data_group_data.members) {
             if (element.group_role == "admin")
                {
                  data_group_data.members[index_arr].btnAdminColor = "primary";
                } else {
                  data_group_data.members[index_arr].btnAdminColor = "secondary";
                }
             
             index_arr += 1;
         };

        var index_arr2 = 0;
        for(const element of data_group_data.groupPlan) {
             if (element.active == "yes")
                {
                  data_group_data.groupPlan[index_arr2].btnActiveColor = "success";
                } else {
                  data_group_data.groupPlan[index_arr2].btnActiveColor = "danger";
                }
             index_arr2 += 1;
         };

        /*
        const nextGroup = data_group_data.map((gp, i) => {
          if (i === 0) {
            return gp;
            } else {
            return gp;
           }
        });
        */
    console.log("handle show groupslist view");
    //console.log(nextGroup);
    //setGroup(nextGroup);
    setGroup(data_group_data);



        const request_public_key = new Request(`${API_ENDPOINT}/back/get-pub-key`, {
        method: 'GET',
        headers: {'Authorization': `Bearer ${token}`}
        });
        console.log("Set public key");
        console.log(request_public_key);
        const response_public_key = await fetch(request_public_key);
        console.log(response_public_key);
        const data_public_key = await response_public_key.json();
        console.log("Data public key");
        console.log(data_public_key.message);
        setPublicKey(data_public_key.message);


    //hack for refreh search
    //setSearchComponent("");
    setSearchComponent(<AgentSearch reloadSearch={reloadSearch} {...props}/>);
    setCustomProxy(<CustomProxy reloadSearch={reloadSearch} {...props}/>);
     setReloadSearch((reloadSearch) => reloadSearch + 1);

    //setCustomProxy(<CustomProxy reloadSearch={reloadSearch} {...props}/>);



    setRenameGroupName(data_group_data.group_name);
    setRenameGroupComment(data_group_data.comment);


}
  
  // Fetch user information on page load
  useEffect(() => {
    const fetchData = async () => {
      if (auth.isAuthenticated()){
        const result = await auth.getUser();
        if (result.userBalance >=0 ) {
               result.btnPlanColor = "outline-light";
               result.spltPlanColor = "outline-light";
             } else {
               result.btnPlanColor = "outline-danger"; 
               result.spltPlanColor = "outline-danger";
             }
        var index_arr = 0;
        result.balanceExpireRound = result.balanceExpire.slice(0, -7);

        for(const element of result.userPlan) {
             if (element.active == "yes")
                {
                  result.userPlan[index_arr].btnActiveColor = "success";
                } else {
                  result.userPlan[index_arr].btnActiveColor = "danger";
                }
             
             index_arr += 1;
         };

        setUser(result);
        console.log("----------  Plans Data  --------------");
        console.log(result);
 
        reloadGroupData();
/*
        const token = localStorage.getItem('token');
      // fetch group data 
        const request_group_data = new Request(`${API_ENDPOINT}/back/get-group`, {
        method: 'GET',
        headers: {'Authorization': `Bearer ${token}`}
        });
        console.log(request_group_data);
        const response_group_data = await fetch(request_group_data);
        console.log(response_group_data);
        const data_group_data = await response_group_data.json();
        data_group_data.btnGroupColor = "outline-light";
        data_group_data.spltGroupColor = "outline-light";
        console.log(data_group_data);
    console.log("handle show groupslist view");
    //console.log(nextGroup);
    //setGroup(nextGroup);

    setGroup(data_group_data);
*/
      };

    };
    fetchData();
  // eslint-disable-next-line
  }, []);




  const handleTariffButton = async (user) => {
     console.log("handleTariffButton");

   };

  const handleCloseTariffsData = () => setShowTariffsData(false);
  const handleClosePlansData = () => setShowPlansData(false);
  const handleCloseGroupWindow = () => setShowGroupData(false);
  const handleCloseDeletedGroups = () => setShowDeletedGroups(false);
  const handleCloseCreateProxy = () => setShowCreateProxy(false);
  const handleCloseCreateAction = () => setShowCreateAction(false);

  const handleShowTariffsData = async (user_data) => {
    var default_proxy_addr = "";

    console.log("handle show tariffs");
    const token = localStorage.getItem('token');
    const request_tariffs = new Request(`${API_ENDPOINT}/listtariffs`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    console.log(request_tariffs);
    const response_tariffs = await fetch(request_tariffs);
    console.log(response_tariffs);
    const data_tariffs = await response_tariffs.json();
    const nextTariffsListView = data_tariffs.map((p, i) => {
      if (i === 0) {
        return p;
        } else {
        return p;
       }
    });
    console.log("handle show tariffslist view");
    console.log(nextTariffsListView);
    setTariffsListView(nextTariffsListView);
    setShowTariffsData(true);
    }



  const handleShowPlansData = async (plans) => {
    /*
    var default_proxy_addr = "";
    console.log("handle show tariffs");
    const token = localStorage.getItem('token');
    const request_tariffs = new Request(`${API_ENDPOINT}/listtariffs`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    console.log(request_tariffs);
    const response_tariffs = await fetch(request_tariffs);
    console.log(response_tariffs);
    const data_tariffs = await response_tariffs.json();
    const nextTariffsListView = data_tariffs.map((p, i) => {
      if (i === 0) {
        return p;
        } else {
        return p;
       }
    });
    console.log("handle show tariffslist view");
    console.log(nextTariffsListView);
    setTariffsListView(nextTariffsListView);
    */

    setShowPlansData(true);
    }



  const handleShowGroupData = async (user_data) => {
    console.log("handle show group");
    const token = localStorage.getItem('token');
    /*
    const request_tariffs = new Request(`${API_ENDPOINT}/listtariffs`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    console.log(request_tariffs);
    const response_tariffs = await fetch(request_tariffs);
    console.log(response_tariffs);
    const data_tariffs = await response_tariffs.json();
    const nextTariffsListView = data_tariffs.map((p, i) => {
      if (i === 0) {
        return p;
        } else {
        return p;
       }
    });
    console.log("handle show tariffslist view");
    console.log(nextTariffsListView);
    setTariffsListView(nextTariffsListView);
    */

    setShowGroupData(true);
    }

  const handleShowCreateProxy = async (user_data) => {
    console.log("handle show create proxy window");
    const token = localStorage.getItem('token');
    setShowCreateProxy(true);
    }

  const handleShowCreateAction = async (user_data) => {
    console.log("handle show create action window");
    const token = localStorage.getItem('token');
    setShowCreateAction(true);
    }

  const handleShowDeletedGroups = async (user_data) => {
    console.log("handle show deleted group");
    const token = localStorage.getItem('token');
    /*
    const request_tariffs = new Request(`${API_ENDPOINT}/listtariffs`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    console.log(request_tariffs);
    const response_tariffs = await fetch(request_tariffs);
    console.log(response_tariffs);
    const data_tariffs = await response_tariffs.json();
    const nextTariffsListView = data_tariffs.map((p, i) => {
      if (i === 0) {
        return p;
        } else {
        return p;
       }
    });
    console.log("handle show tariffslist view");
    console.log(nextTariffsListView);
    setTariffsListView(nextTariffsListView);
    */

    setShowDeletedGroups(true);
    }


    
  // Function for apply tariff to user
  const applyTariff = async (data_in, id) => {
    console.log(data_in)
    console.log("start apply Tariff")
    // Add on test payment_id
    const query_data={
            "plan_id":data_in.id,
            "user_id":"test",
            "payment_id":"64283be2de4b1fc68813fc15",
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/test-add-plan`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log(data);
        reloadGroupData();

  };

  const detailPlan = async (data_in, id) => {
    console.log(data_in)
    console.log(id)

  };


  // Function for create group
  const createGroup = async () => {
    console.log(createGroupName)
    console.log(createGroupComment)
    console.log("start create group")
    
    const query_data={
            "action":"create",
            "group_name":createGroupName,
            "group_comment":createGroupComment,
            "group_id":"",
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
    setCreateGroupName("");
    setCreateGroupComment("");
        reloadGroupData();
  };

  // Function for create custom proxy
  const createCustomProxy = async () => {
    console.log(createCustomProxyHost)
    console.log(createCustomProxyIp)
    console.log(createCustomProxyPortMin)
    console.log(createCustomProxyPortMax)
    console.log("start create custom proxy")
    
    const query_data={
            "host":createCustomProxyHost,
            "ip":createCustomProxyIp,
            "port_min":createCustomProxyPortMin,
            "port_max":createCustomProxyPortMax,
            "price_multiplier":"1",
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/proxy-custom-add`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log("Reply");
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
    setCreateGroupName("");
    setCreateGroupComment("");
        reloadGroupData();
        //setShowCreateProxy(false)
    //setOutMessage(data.message);


    setStdOutMessage(data.full_stdout);
    setStdErrMessage(data.full_stderr);


  };

  // Function for create custom action
  const createCustomAction = async () => {
    console.log(customAction)
    console.log("start create custom action")
    

    /*const query_data={
            "name":createCustomActionName,
            };*/
    const query_data={
            "name":createCustomActionName,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY");
    console.log(customAction);
    const request = new Request(`${API_ENDPOINT}/back/action-custom-add`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(customAction),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
    //setCreateGroupName("");
    //setCreateGroupComment("");
    //reloadGroupData();
    //setShowCreateProxy(false)
    setOutMessage(data.message);


  };



  // Function for create group
  const renameGroup = async () => {
    console.log(renameGroupName)
    console.log(renameGroupComment)
    console.log("start create group")
    
    const query_data={
            "action":"rename",
            "group_name":renameGroupName,
            "group_comment":renameGroupComment,
            "group_id":"",
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
        reloadGroupData();
  };

  // Function for delete group
  const deleteCurrentGroup = async (group_id) => {
    console.log("start delete group group")
    
    const query_data={
            "action":"delete",
            "group_name":"",
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
        reloadGroupData();
  };


  // Function for create group
  const changeGroup = async (group_id) => {
    //console.log(group_id)
    console.log("start change group")
    
    const query_data={
            "action":"set_current",
            "group_name":"",
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
    
        reloadGroupData();
  };

  // Function for create group
  const addMemberToGroup = async (type_add) => {
    //console.log(group_id)
    console.log("start add member");
    console.log(memberForAdd);
    console.log(type_add);


    const query_data={
            "action":"add",
            "group_name":"",
            "group_comment":type_add,
            "group_id":memberForAdd,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-member-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
    
        reloadGroupData();

  };

  // Function for del member from
  const delMemberFromGroup = async (group_id) => {
    //console.log(group_id)
    console.log("del member from group");
    console.log(group_id);


    const query_data={
            "action":"del",
            "group_name":"",
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-member-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
            reloadGroupData();
  };

  // Function for del member from
  const blockMemberFromGroup = async (group_id) => {
    //console.log(group_id)
    console.log("block member from group");
    console.log(group_id);


    const query_data={
            "action":"block",
            "group_name":"",
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-member-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
        reloadGroupData();    
  };

  // Function for admin member from
  const adminMemberFromGroup = async (group_id) => {
    //console.log(group_id)
    console.log("set member as admin group");
    console.log(group_id);

    const query_data={
            "action":"admin",
            "group_name":"",
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-member-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
        reloadGroupData();
  };

  // Function for undel group
  const unDelGroup = async (group_id) => {
    //console.log(group_id)
    console.log("undel  group");
    console.log(group_id);

    const query_data={
            "action":"undel",
            "group_name":"",
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
        reloadGroupData();
  };


  // Function for del dependence
  const delMemberFromDependencies = async (dep_id) => {
    //console.log(group_id)
    console.log("del dependence");
    console.log(dep_id);

/*
    const query_data={
            "action":"del",
            "group_name":"",
            "group_comment":"",
            "group_id":group_id,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-member-status-group`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    //setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    //setStdoutMessage(data.full_stdout)
    //setStderrMessage(data.full_stderr)
            reloadGroupData();
*/  

};


/*
 const delFromCustomActionArray = async (name, id) => {
    //console.log(group_id)
    console.log("del from array");
    console.log(name, id);

    await setCustomAction({
  ...customAction, // Copy other fields
  conditions: { // but replace the artwork
     ...customAction.conditions, // with the same one
    [id]:name
     }
  });

};
*/

// del element from array in customAction
 const delFromCustomActionArray = async (changed_array, changed_value, old_action) => {
    //console.log(group_id)
    //const new_deps = await customAction.dependencies.filter(a => a.id !== changed_value );
    var new_deps = "";
    var deleted_deps = "";

    if (changed_array == "environment_variables" ) {
        new_deps = await customAction[changed_array].filter(a => {return a.name !== changed_value} );
        deleted_deps = await customAction[changed_array].filter(a => {return a.name === changed_value} );
        await setEnvironment_variablesForAdd(deleted_deps[0]);
      } else {
        new_deps = await customAction[changed_array].filter(a => {return a !== changed_value} );
        deleted_deps = await customAction[changed_array].filter(a => {return a === changed_value} );
     }
    const newCustomAction = {
      ...customAction, // Copy other fields
      [changed_array]:new_deps
  };
    await setCustomAction(newCustomAction);

};


// del element from array in customAction
 const delFromCustomActionArrayPort = async (changed_array, changed_value_value, changed_value_port_type, changed_value_vm_id, old_action) => {
    //console.log(group_id)
    //const new_deps = await customAction.dependencies.filter(a => a.id !== changed_value );

    console.log(changed_array, changed_value_value, changed_value_port_type, changed_value_vm_id, old_action);

    console.log(customAction[changed_array]);

    const new_deps = await customAction[changed_array].filter(a => {return a.value !== changed_value_value || a.port_type !== changed_value_port_type || a.vm_id !== changed_value_vm_id} );
    const deleted_deps = await customAction[changed_array].filter(a => {return a.value === changed_value_value && a.port_type === changed_value_port_type && a.vm_id === changed_value_vm_id} );

    console.log("new_deps");
    console.log(new_deps);
    console.log("deleted_deps");
    console.log(deleted_deps);

    //new state
    const newCustomAction = {
      ...customAction, // Copy other fields
      [changed_array]:new_deps
  };
    await setCustomAction(newCustomAction);

    await setPortsForAdd(deleted_deps[0]);


};


  //function update customAction
  const changeCreateCustomAction = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, customAction);
    await setCustomAction({
  ...customAction, // Copy other fields
    [variable_for_change]:value
  });
    console.log("after");
    console.log(variable_for_change, value, customAction);    
  };


  //changeEnvironment_variablesForAdd
  //function update customAction
  const changeEnvironment_variablesForAdd = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, environment_variablesForAdd);
    if (variable_for_change=="all") {
    await setEnvironment_variablesForAdd({
    "name":"",
    "comment":"",
    "value":""
  });

}
    else {
    await setEnvironment_variablesForAdd({
  ...environment_variablesForAdd, // Copy other fields
    [variable_for_change]:value
  });

   }
    console.log("after");
    console.log(variable_for_change, value, environment_variablesForAdd);
  };

  //changePortsForAdd
  const changePortsForAdd = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, portsForAdd);
    if (variable_for_change=="all") {
    await setPortsForAdd({
    "name":"",
    "value":"",
    "port_type":"link",
    "vm_id":"",
    "comment":""
  });

   } else {
    await setPortsForAdd({
  ...portsForAdd, // Copy other fields
    [variable_for_change]:value
  });

   }
    console.log("after");
    console.log(variable_for_change, value, portsForAdd);
  };


  //function update customAction.conditions
  const changeCreateCustomActionConditions = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, customAction);
    await setCustomAction({
  ...customAction, // Copy other fields
  conditions: { // but replace the
     ...customAction.conditions, // with the same one
    [variable_for_change]:value
     }
  });
        console.log("after");
    await console.log(variable_for_change, value, customAction);
  };







  //function update customAction.conditions
  const addToCustomActionArray = async (changed_array, variable_for_add) => {
    console.log("before");
    console.log(changed_array, variable_for_add);

    await setCustomAction({
     ...customAction, // Copy other fields
     [changed_array]: [
        ...customAction[changed_array],
        variable_for_add 
     ]

     /*
      conditions: { // but replace the 
     ...customAction.conditions, // with the same one
    [variable_for_change]:value
     }
     */
  });
        console.log("after");
    await console.log(changed_array, variable_for_add, customAction);
  };



  // Function to call logout
  const callLogout = async () => {
    auth.logout(() => {
      history.push('/');
    });
  };
  
  return (
    <>
      <Navbar className="align-middle justify-content-between" bg="dark" variant="dark">
        <div>
          <Navbar.Brand href="/">


          <Navbar.Brand>                       
         <Col > <center><strong>CloudLocalNet</strong></center></Col>
        <Col> <Button href="https://agent.cloudlocalnet.com/">Download Virtual Machine image</Button> </Col>
         </Navbar.Brand>   

          </Navbar.Brand>
        </div>
        <div>
          <Col>
          <Row>
          <label className="ml-4 text-white" style={{}}><FaUserCircle size={21}/></label>
          <label className="ml-4 text-white">{user.firstName + " " + user.lastName}</label>
          <label className="ml-4 text-white"><strong>{group.group_admin_permit}</strong></label>
          </Row><Row>
        <Dropdown key="groupButton" className="ml-4" as={ButtonGroup}>
          <Button className="ml-4" size="sm" variant={group.btnGroupColor} id="button-for-groups" onClick={(e) => handleShowGroupData(group)}> Group:{group.group_name}  </Button>
          <Dropdown.Toggle split variant={group.spltGroupColor} id="dropdown-split-basic-groups" />
          <Dropdown.Menu>
                <Dropdown.Item key={0} href="#" onClick={(e) => changeGroup(group.base_group_id)}>Base Group</Dropdown.Item>
                <Dropdown.Item key={1} href="#" onClick={(e) => changeGroup(group.prev_group_id)}>PREV Group</Dropdown.Item>

            {group.members_for_change && group.members_for_change.map((group_in_list,i) =>
                <Dropdown.Item key={i+2} href="#"  onClick={(e) => changeGroup(group_in_list.group_id)}>{group_in_list.group_name}  </Dropdown.Item>
            )}
            <Dropdown.Divider />
                <Dropdown.Item key={99} href="#" onClick={(e) => handleShowDeletedGroups(group)}>Deleted groups</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
          </Row>
          </Col>
          </div>

          <div>
          <Col>
          <Row>
          <label className="ml-4 text-white" style={{}}><strong>Custom</strong></label>
          </Row><Row>
          <ButtonGroup>
          <Button variant="outline-light" size="sm" id="button-for-proxy" onClick={(e) => handleShowCreateProxy(group)}>Proxy</Button>
          <Button variant="outline-light" size="sm" id="button-for-proxy" onClick={(e) => handleShowCreateAction(group)}>Action</Button>
          </ButtonGroup>
         </Row>
         </Col>
         </div>
         <div>         
         <Col>
          <Row>
          <label className="ml-4 text-white">expire: {group.balanceExpireRound}</label>
          </Row>
         <Row>
        <Dropdown key="balanceButton" className="ml-4" as={ButtonGroup}>
          <Button className="ml-4" size="sm" variant={group.btnPlanColor} id="button-for-plans" onClick={(e) => handleShowTariffsData(user)}> Balance:{group.groupBalance}  </Button>
          <Dropdown.Toggle split variant={group.spltPlanColor} id="dropdown-split-basic-plans" />
          <Dropdown.Menu>
                <Dropdown.Item key={1001} href="#" onClick={(e) => handleShowPlansData(group)}>View plans in group</Dropdown.Item>

             {/*
                       {group.groupPlan && group.groupPlan.map((plan,i) =>
            <Dropdown.Item key={i} href="#">{plan.direction} {plan.id_res} Points:{plan.points} <Button variant={plan.btnActiveColor}>active</Button></Dropdown.Item>
                        )}
             */}

          </Dropdown.Menu>
        </Dropdown>
          </Row>
           </Col>
          </div>
           <div>
          <Button className="ml-4" size="sm" variant="outline-light" onClick={callLogout}>Log Out</Button>
        </div>

      <Modal show={showGroupData} onHide={handleCloseGroupWindow} >
        <Modal.Header className="px-4" closeButton >
          <Modal.Title className="ms-auto" > {group.group_name}({group.group_type}) </Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <>
      <Button
        size="sm"
        onClick={() => setOpenIdForAdd(!openIdForAdd)}
        aria-controls="example-collapse-text"
        aria-expanded={openIdForAdd}
      >
        View GroupID For Add
      </Button>
      <div style={{ minHeight: '10px' }}>
        <Collapse in={openIdForAdd} dimension="width">
          <div id="example-collapse-text">
            <Card body style={{ width: '450px' }}>
            {group.group_id_for_add}
            </Card>
          </div>
        </Collapse>
      </div>
    </>
            Group Members
               <ListGroup>
                       {group.members&& group.members.map((group_member,iii) =>
                 <ListGroup.Item key={iii}>
                          {group_member.group_name}  {'   '}
                          <ButtonGroup>
                            <Button variant={group_member.btnDelColor} size="sm" onClick={(e) => delMemberFromGroup(group_member.group_id)}> Del </Button>
                            <Button variant={group_member.btnBlockColor} size="sm" onClick={(e) => blockMemberFromGroup(group_member.group_id)}> Block </Button>
                            <Button variant={group_member.btnAdminColor} size="sm" onClick={(e) => adminMemberFromGroup(group_member.group_id)}> Admin </Button>
                          </ButtonGroup>
                 </ListGroup.Item>
                     )}
              </ListGroup>

         <Form>
        <Form.Group controlId="addMemberToGroupForm1">
          <Form.Label>Add Member by GroupID or e-mail</Form.Label>
          <Form.Control type="text" placeholder="user e-mail OR GroupID for Add" value={memberForAdd} onChange={(f) => setMemberForAdd(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => addMemberToGroup("EMAIL")}> Add by E-mail </Button>
          <Button variant="secondary" size="sm" onClick={(e) => addMemberToGroup("ID")}> Add by ID </Button>
        </Form.Group>
        </Form>

         <Form>
        <Form.Group controlId="createGroupForm1">
          <Form.Label>Create Group {createGroupName}</Form.Label>
          <Form.Control type="text" placeholder="group name" value={createGroupName} onChange={(f) => setCreateGroupName(f.currentTarget.value)} />
          <Form.Control type="text" placeholder="group comment" value={createGroupComment} onChange={(f) => setCreateGroupComment(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => createGroup()}>
            Create
          </Button>
        </Form.Group>
        </Form>

        <Form>
        <Form.Group controlId="renameGroupForm1">
          <Form.Label>Rename Group {group.group_name}</Form.Label>
          <Form.Control type="text" placeholder="group name" value={renameGroupName} onChange={(f) => setRenameGroupName(f.currentTarget.value)} />
          <Form.Control type="text" placeholder="group comment" value={renameGroupComment} onChange={(f) => setRenameGroupComment(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => renameGroup()}>
            Rename
          </Button>
        </Form.Group>
        </Form>
          <Button variant="secondary" size="sm" onClick={(e) => deleteCurrentGroup(group.group_id_for_add)}>
            Delete Current Group
          </Button>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>



      <Modal show={showPlansData} onHide={handleClosePlansData} >
        <Modal.Header closeButton>
          <Modal.Title>View Plans in this group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Accordion defaultActiveKey="0">




                       {group.groupPlan && group.groupPlan.map((plans,ik) =>


            <Card key={ik+1}>
            <Accordion.Toggle as={Card.Header} eventKey={ik+1}>
               {plans.direction} {plans.tp_name} Points:{plans.points} <Button size="sm" variant={plans.btnActiveColor}>active</Button>
            </Accordion.Toggle>
              <Accordion.Collapse eventKey={ik+1}>
               <Card.Body>
                <Form> 
                   <Row> start time: {plans.start_time}   </Row>
                   <Row> end time: {plans.end_time}   </Row>

                   { plans.direction=='inc' && 
                   <>
                   <Row> Name: {plans.tp_name}   </Row>
                   <Row> Comment: {plans.tp_comment}   </Row>
                   <Row> Duration: {plans.tp_duration}   </Row>
                   </>
                   }
                   { plans.direction=='dec' && 
                   <>
                   <Row> Name: {plans.tp_name}   </Row>
                   <Row> Type: {plans.tp_type_res}   </Row>
                   <Row> Type_port: {plans.tp_type_port}   </Row>
                   <Row> Value: {plans.tp_value}   </Row>
                   <Row> Proxy address: {plans.tp_proxy_addr}   </Row>
                   <Row> vm_id: {plans.tp_vm_id}   </Row>

                   </>
                   }

                 <Button variant="secondary" size="sm" onClick={(e) => detailPlan(plans)}> Details </Button>

                </Form>
               </Card.Body>
              </Accordion.Collapse>
            </Card>


                        )}




          </Accordion>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>



      <Modal show={showTariffsData} onHide={handleCloseTariffsData} >
        <Modal.Header closeButton>
          <Modal.Title>Add tariff to user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Accordion defaultActiveKey="0">
                   {tariffsListView.map((data,jk) =>
            <Card key={jk+1}>
            <Accordion.Toggle as={Card.Header} eventKey={jk+1}>
               {data.name}
            </Accordion.Toggle>
              <Accordion.Collapse eventKey={jk+1}>
               <Card.Body>
                <Form> <Row> {data.comment}   </Row>
                 <Button variant="secondary" size="sm" onClick={(e) => applyTariff(data)}> Apply </Button>
                </Form>
               </Card.Body>
              </Accordion.Collapse>
            </Card>
                        )}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeletedGroups} onHide={handleCloseDeletedGroups} >
        <Modal.Header closeButton>
          <Modal.Title>Deleted Groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                       {group.deleted_groups&& group.deleted_groups.map((group_deleted,iji) =>



                 <ListGroup.Item key={iji}>
                          {group_deleted.group_name}  {'   '}
                          <ButtonGroup>
                            <Button variant="primary" size="sm" onClick={(e) => unDelGroup(group_deleted.group_id)}> UnDel </Button>
                          </ButtonGroup>
                 </ListGroup.Item>




                    )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateProxy} onHide={handleCloseCreateProxy} >
        <Modal.Header closeButton>
          <Modal.Title>Create Custom Proxy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card key="customproxydns">
           <Card.Title>
               1. Add A-Record you proxy server to DNS
           </Card.Title>
        </Card>

        <Card key="customproxykeyadd">
           <Card.Title>2. Execute on you server</Card.Title>
           <Card.Body>
              mkdir -p /root/.ssh && echo "{publicKey}" >> /root/.ssh/authorized_keys && chmod -R 600 /root/.ssh
           </Card.Body>
        </Card>

        <Card key="customproxyaddform">
           <Card.Title>3. Configure you custom proxy server</Card.Title>
           <Card.Body>

         <Form>
        <Form.Group controlId="createCustomProxy1">
          <Form.Label>Custom Proxy: {createCustomProxyHost}</Form.Label>
          <Form.Control type="text" placeholder="proxy host" value={createCustomProxyHost} onChange={(f) => setCreateCustomProxyHost(f.currentTarget.value)} />
          <Form.Control type="text" placeholder="proxy ip" value={createCustomProxyIp} onChange={(f) => setCreateCustomProxyIp(f.currentTarget.value)} />
          <Form.Control type="text" placeholder="proxy min port" value={createCustomProxyPortMin} onChange={(f) => setCreateCustomProxyPortMin(f.currentTarget.value)} />
          <Form.Control type="text" placeholder="proxy max port" value={createCustomProxyPortMax} onChange={(f) => setCreateCustomProxyPortMax(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => createCustomProxy()}>
            Create
          </Button>
        </Form.Group>
        </Form>
           </Card.Body>
        </Card>


        <Alert variant='info' style={ stdOutMessage!=='' ? {display:"block"} : {display:"none"}}>
          {stdOutMessage}
        </Alert>

        <Alert variant='danger' style={ stdErrMessage!=='' ? {display:"block"} : {display:"none"}}>
          {stdErrMessage}
        </Alert>


        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>







      <Modal show={showCreateAction} onHide={handleCloseCreateAction} >
        <Modal.Header closeButton>
          <Modal.Title>Create Custom Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>

         <Form>
        <Form.Group controlId="createCustomAction1">
          <Form.Label>Action Settings: {createCustomActionName}</Form.Label>
          <Form.Control type="text" placeholder="Action Name" value={customAction.name} onChange={(f) => changeCreateCustomAction("name",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="Comment" value={customAction.comment} onChange={(f) => changeCreateCustomAction("comment",f.currentTarget.value)} />

          <Form.Control type="text" placeholder="conditions CPU" value={customAction.conditions.CPU} onChange={(f) => changeCreateCustomActionConditions("CPU",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="conditions DISK" value={customAction.conditions.DISK} onChange={(f) => changeCreateCustomActionConditions("DISK",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="conditions MEM" value={customAction.conditions.MEM} onChange={(f) => changeCreateCustomActionConditions("MEM",f.currentTarget.value)} />
            dependencies
                       {customAction.dependencies && customAction.dependencies.map((dep,dep_i) =>
                            <Button key={dep_i} variant="secondary" size="sm" onClick={(e) => delFromCustomActionArray("dependencies", dep, customAction)}> {dep} </Button>
                     )}

         <Form>
        <Form.Group controlId="addMemberToArray1">
          <Form.Label>Add dependence</Form.Label>
          <Form.Control type="text" placeholder="type dependence" value={dependenceForAdd} onChange={(f) => setDependenceForAdd(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("dependencies", dependenceForAdd); setDependenceForAdd("") }}>Add</Button>
        </Form.Group>
        </Form>
            tags
                       {customAction.tags && customAction.tags.map((tag,tag_i) =>
                            <Button key={tag_i} variant="secondary" size="sm" onClick={(e) => delFromCustomActionArray("tags", tag, customAction)}> {tag} </Button>
                     )}

         <Form>
        <Form.Group controlId="addMemberToArray2">
          <Form.Label>Add tag</Form.Label>
          <Form.Control type="text" placeholder="type tag" value={tagForAdd} onChange={(f) => setTagForAdd(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("tags", tagForAdd); setTagForAdd("") }}>Add</Button>
        </Form.Group>
        </Form>


          <Form.Control type="text" placeholder="source" value={customAction.source} onChange={(f) => changeCreateCustomAction("source",f.currentTarget.value)} />

          <Form.Control as="select" defaultValue="None" value={customAction.auth_type} onChange={(f) => changeCreateCustomAction("auth_type",f.currentTarget.value)}>
                             <option key="1" value="None">None</option>                             
                             <option key="2" value="log_pass">log_pass</option>
                             <option key="3" value="token">token</option>
                             <option key="4" value="private_key">private_key</option>
          </Form.Control>

          <Form.Control type="text" placeholder="source_credentials" value={customAction.source_credentials} onChange={(f) => changeCreateCustomAction("source_credentials",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="branch" value={customAction.branch} onChange={(f) => changeCreateCustomAction("branch",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="source_path" value={customAction.source_path} onChange={(f) => changeCreateCustomAction("source_path",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="source_run_file" value={customAction.source_run_file} onChange={(f) => changeCreateCustomAction("source_run_file",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="action_timeout" value={customAction.action_timeout} onChange={(f) => changeCreateCustomAction("action_timeout",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="source_timeout" value={customAction.source_timeout} onChange={(f) => changeCreateCustomAction("source_timeout",f.currentTarget.value)} />



            environment_variables
                       {customAction.environment_variables && customAction.environment_variables.map((env,env_i) =>
                            <Button key={env_i} variant="secondary" size="sm" onClick={(e) => delFromCustomActionArray("environment_variables", env.name, customAction)}> {env.name} </Button>
                     )}


         <Form>
        <Form.Group controlId="addMemberToArray3">
          <Form.Label>Add environment_variables</Form.Label>
          <Form.Control type="text" placeholder="type name environment_variables" value={environment_variablesForAdd.name} onChange={(f) => changeEnvironment_variablesForAdd("name", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="type comment environment_variables" value={environment_variablesForAdd.comment} onChange={(f) => changeEnvironment_variablesForAdd("comment", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="type value environment_variables" value={environment_variablesForAdd.value} onChange={(f) => changeEnvironment_variablesForAdd("value", f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("environment_variables", environment_variablesForAdd); changeEnvironment_variablesForAdd("all", "") }}>Add</Button>
        </Form.Group>
        </Form>

            ports
                       {customAction.ports && customAction.ports.map((port,port_i) =>
                            <Button variant="secondary" size="sm" onClick={(e) => delFromCustomActionArrayPort("ports", port.value, port.port_type, port.vm_id, customAction)}> {port.value}:{port.port_type}({port.vm_id}) </Button>
                     )}


         <Form>
        <Form.Group controlId="addMemberToArray4">
          <Form.Label>Add ports</Form.Label>
          <Form.Control type="text" placeholder="name port" value={portsForAdd.name} onChange={(f) => changePortsForAdd("name", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="comment port" value={portsForAdd.comment} onChange={(f) => changePortsForAdd("comment", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="value port" value={portsForAdd.value} onChange={(f) => changePortsForAdd("value", f.currentTarget.value)} />
          <Form.Control as="select" defaultValue="link" value={portsForAdd.port_type} onChange={(f) => changePortsForAdd("port_type", f.currentTarget.value)}>
                             <option key="1" value="link">link</option>                             
                             <option key="2" value="port">port</option>
          </Form.Control>
          <Form.Control type="text" placeholder="vm_id port" value={portsForAdd.vm_id} onChange={(f) => changePortsForAdd("vm_id", f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("ports", portsForAdd); changePortsForAdd("all", "") }}>Add</Button>
        </Form.Group>
        </Form>

        <Form>
        <Form.Group controlId="addMemberToArray5">
          <Form.Label>Type Action</Form.Label>
          <Form.Control as="select" defaultValue="private" value={customAction.action_type} onChange={(f) => changeCreateCustomAction("action_type",f.currentTarget.value)}>
                             <option key="1" value="private">private</option>
                             <option key="2" value="public">public</option>
          </Form.Control>
        </Form.Group>
        </Form>


          <Button variant="secondary" size="sm" onClick={(e) => createCustomAction()}>
            Create
          </Button>
        </Form.Group>
        </Form>
        <Alert variant='info' style={ outMessage!=='' ? {display:"block"} : {display:"none"}}>
          {outMessage}
        </Alert>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>


      </Navbar>
      <Container className="mt-4">
        <Row>
          <Col className="mt-4">
            <h2>Welcome {user.firstName}!</h2>
            <Alert variant={'primary'}>You have been successfully authenticated.</Alert>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Row>
          <Col className="mt-4">
             {customProxy}
            {searchComponent}

          </Col>
        </Row>
      </Container>

      <Container className="mt-4">
        <Row>
          <Col>
            {/*  <Button href="https://agent.cloudlocalnet.com/">Download Virtual Machine image</Button> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};
