import React from 'react';
import { Navbar, Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { Login } from '../auth/login';
import { Register } from '../auth/register';
import { Forgot } from '../auth/forgot';

import { AgentSearch } from '../utils/agentsearch';

//import ReCAPTCHA from "react-google-recaptcha";
//import Constants from "../constants";

export const Landing = (props) => {
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <Navbar.Brand>
         <Col > <center><strong>CloudLocalNet</strong></center></Col>
        <Col> <Button href="https://agent.cloudlocalnet.com/">Download Virtual Machine image</Button> </Col>
         </Navbar.Brand>
        </Navbar.Brand>
      </Navbar>
      <Container className="mt-4">

        <Row>
          <Col className="mt-4">
<Row> </Row>
<Row>Installing the agent Virtual Machine: </Row>
<Row> ------- </Row>
<Row>1. Download and install Virtualbox and Extensions for it (<a href="https://www.virtualbox.org/">https://www.virtualbox.org/</a>) </Row>
<Row>2. Download the OVF image of the agent (<a href="https://agent.cloudlocalnet.com/">https://agent.cloudlocalnet.com/</a>) </Row>
<Row>3. In VirtualBox File - Import Appliance of the downloaded OVF image </Row>
<Row>4. In "Import Parameters" specify a new unique Name. </Row>
<Row>5. ON the virtual machine Settings - System (and set the required amount of memory), Processor (and set the required number of cores), Network - Bridged Adapter & OK </Row>
<Row>6. In Shared Folders, connect disks with Auto Mount (in theory, C and D should connect themselves) </Row>
<Row>7. In the menu after starting the virtual machine Devices-Insert Guest Addons CD Image </Row>
<Row>----- In the current version for development ----- </Row>
<Row>8. After starting the Virtual Machine, log in under login:root password:cloudlocalnet </Row>
<Row>9. In the console, type "agent" and press Enter </Row>
<Row> </Row>
<Row>The rest is through Actions on the front - the Search button searches for this running virtual machine on the network and you can bind it, roll out actions </Row>
<Row> </Row>
          </Col>
        </Row>


        <Row>
          <Col className="mt-4">
            <AgentSearch {...props}/>
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
          <h2>Hello!</h2>
          <Alert variant={'primary'}>If you want to get full functionality, please register.</Alert>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} className="mt-4">
            <Login {...props}/>
            <Forgot {...props}/>     
          </Col>
          <Col lg={6} md={6} sm={12} className="mt-4">
            <Register {...props}/>
          </Col>
        </Row>

        <Row>
        <Col className="mt-4">  </Col>
         </Row>

        { /* <Row>
        <Col className="mt-4">     <Button href="https://agent.cloudlocalnet.com/">Download Virtual Machine image</Button> </Col>
        </Row>
         */ }
      </Container>
    </>
  );
};
