const CAPTCHA_SITE_KEY = "6LdVbOkjAAAAALscXe8t8jf9FAT-_CYo7kdW-bc_";
//const API_ENDPOINT = "http://dev.cloudlocalnet.com:8000";
const API_ENDPOINT = "https://dev.cloudlocalnet.com";


class Constants {
  static CAPTCHA_SITE_KEY = CAPTCHA_SITE_KEY;
  static API_ENDPOINT = API_ENDPOINT;
}

export default Constants;
